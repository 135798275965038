
import { Component, Vue, Prop } from 'vue-property-decorator';
import FleetHistoryPopup from '@/modules/cars/modules/cars-price-history/components/fleet-history.popup.vue';

@Component({
    components: {
        FleetHistoryPopup,
    },
})
export default class FleetPriceHistoryModal extends Vue {
    @Prop({ required: false })
    historyDay?: string;
    @Prop({
        type: String,
    })
    private source?: string;
}
