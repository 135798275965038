
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import type Day from '@/modules/common/types/day.type';
import FleetHistoryService, { FleetHistoryServiceS } from '@/modules/cars/modules/cars-price-history/fleet-history.service';
import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import Currency from '@/modules/common/components/currency.vue';
import PercentFilter from '@/modules/common/filters/percent.filter';
import MIPriceFilter from '@/modules/common/filters/mi-price.filter';
import FleetService, { FleetServiceS } from '@/modules/cars/modules/fleet/fleet.service';

export interface TableData {
    brokerName: string,
    total: number,
    myBroker: boolean,
    borderColor?: string,
}

@Component({
    filters: { PercentFilter, MIPriceFilter },
    components: {
        Currency,
    },
})
export default class FleetHistoryTable extends Vue {
    @inject(UserServiceS) private userService!: UserService;
    @inject(FleetHistoryServiceS) private fleetHistoryService!: FleetHistoryService;
    @inject(FleetServiceS) private fleetService!: FleetService;

    @Prop({
        type: Number,
        default: null,
    })
    private tableDay!: Day | null;

    @Prop({
        type: String,
    })
    private source?: string;

    get tableData() {
        let tableData: TableData[] = [];

        if (!this.tableDay) {
            return [];
        }

        const data = this.fleetService.brokersDataByDay(this.tableDay, this.source);

        if (!data) {
            return tableData;
        }

        tableData = data.map((item: TableData, index: number) => ({
            ...item,
            borderColor: !item.myBroker ? this.fleetService.getBrokersGraphColor(index) : CURRENT_HOTEL_GRAPH_COLOR,
        }));

        tableData.sort((a, b) => b.total - a.total);

        return tableData;
    }

    get carClasses() {
        if (!this.tableDay) {
            return [];
        }
        return this.fleetService.carCategoriesByDay(this.tableDay, this.source);
    }
}
