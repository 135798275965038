
import { Component, Prop, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import FleetService, { FleetServiceS } from '@/modules/cars/modules/fleet/fleet.service';

@Component
export default class FleetHistoryFilters extends Vue {
    @inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @inject(FleetServiceS) private fleetService!: FleetService;

    @Prop({
        type: String,
        default: '',
    })
    private source?: string;

    get country() {
        return this.fleetService.country;
    }

    get pickupLocation() {
        return this.fleetService.pickupLocation;
    }

    get sourceName() {
        return this.source;
    }

    get pos() {
        return this.fleetService.pos;
    }

    get lok() {
        return this.fleetService.lor;
    }

    get carCategory() {
        return `${this.fleetService.carClasses.length} selected`;
    }

    get categoryItem() {
        return this.fleetService.carClasses;
    }

    get transmission() {
        return this.fleetService.transmission;
    }

    get millage() {
        return this.fleetService.mileage;
    }
}
