
import { Component, Prop, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import PopupEvents from '@/modules/events/components/popup-events.vue';
import CustomCheckbox from '@/modules/common/components/ui-kit/custom-checkbox.vue';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import { ChartOptions, ChartData, ChartDataSets } from 'chart.js';
import FleetHistoryService, { FleetHistoryServiceS } from '@/modules/cars/modules/cars-price-history/fleet-history.service';
import type Day from '@/modules/common/types/day.type';
// @ts-ignore
import ModalWrapper, { DialogOptions } from '@/modules/common/components/modal-wrapper.vue';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import FleetService, { FleetServiceS } from '@/modules/cars/modules/fleet/fleet.service';
import moment from 'moment';
import FleetHistoryFilters from './fleet-history-filters.vue';
import FleetHistoryHeader from './fleet-history-header.vue';
import FleetHistoryTable from './fleet-history-table.vue';

@Component({
    components: {
        CustomGraph,
        CustomCheckbox,
        PopupEvents,
        ModalWrapper,
        FleetHistoryHeader,
        FleetHistoryFilters,
        FleetHistoryTable,
        LoaderWrapper,
    },
})
export default class FleetHistoryPopup extends Vue {
    @inject(UserServiceS) private userService!: UserService;
    @inject(FleetHistoryServiceS) private fleetHistoryService!: FleetHistoryService;
    @inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @inject(FleetServiceS) private fleetService!: FleetService;

    @Prop({ required: false })
    private day!: string;

    @Prop({
        type: String,
    })
    private source?: string;

    @Prop({ type: Object })
    modalOptions!: DialogOptions;

    mounted(): void {
        this.setTableDay('Today');
    }

    handleTooltipClick(newPos?: string) {
        this.fleetHistoryService.storeState.lastTooltipPos = newPos || null;
    }

    get sourceName() {
        return this.source;
    }

    get lastTooltipPos() {
        return null;
    }

    get tableDay(): Day | null {
        return parseInt(this.$route.params.day, 10) as Day;
    }

    setTableDay(day: Day | 'Today') {
        this.fleetHistoryService.storeState.tableDay = (day !== 'Today' ? Number(Math.abs(day) + 1) : 1) as Day;
    }

    resetPopupData() {
        this.setTableDay('Today');
        this.handleTooltipClick();
        this.fleetHistoryService.storeState.loading.reset();
    }

    handleDownloadCLick() {

    }

    get currentBrokerId() {
        return 2;
    }

    get options() {
        return {
            maintainAspectRatio: false,
            elements: {
                line: {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
                point: {
                    radius: 4,
                    backgroundColor: 'white',
                },
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: true,
                        borderDash: [0, 1],
                        offsetGridLines: true,
                        color: '#ECF1F5',
                    },
                }],
                yAxes: [{
                    gridLines: {
                        display: true,
                        offsetGridLines: true,
                        borderDash: [0, 4],
                        color: '#ECF1F5',
                        zeroLineWidth: 0,
                    },
                    ticks: {
                        autoSkip: true,
                        padding: 10,
                        callback(value) {
                            return `${value}`;
                        },
                    },
                }],
            },
            legend: {
                display: false,
            },
            plugins: {
                filler: {
                    propagate: true,
                },
            },
        } as ChartOptions;
    }

    get chartData(): ChartData | null {
        const currentDay = moment().date();
        const labels = this.documentFiltersService.days.map((_: any, index: number) => {
            if (index === 0) {
                return 'Today';
            }
            const day = index;
            return String(day).length === 1 ? `-0${day}` : -day;
        }).splice(0, currentDay).reverse();
        const brokers = this.fleetService.companies;

        if (!brokers) {
            return null;
        }
        const datasets: ChartDataSets[] = brokers.map((brokerName: string, index: number) => ({
            data: this.documentFiltersService.days.splice(0, currentDay)
                .map(day => this.fleetService.carsBrokersCountDay(day, this.source, brokerName)),
            borderDash: this.currentBrokerId === index ? [] : [8, 4],
            borderColor: brokerName === this.fleetService.currentBroker
                ? CURRENT_HOTEL_GRAPH_COLOR
                : this.fleetHistoryService.getBrokersGraphColor(index),
            lineTension: 0,
            borderJoinStyle: 'round',
        }));
        return {
            labels,
            datasets,
        };
    }
}
